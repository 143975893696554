@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap);
@font-face {
  font-family: 'Rajdhani';
  src: local('Gallient'), url(/static/media/Gallient-Regular.ecce6fb9.ttf) format('truetype');
}
@font-face {
  font-family: 'Rajdhani';
  src: local('Grotesk-Regular'), url(/static/media/AllrounderGroteskTest-Regular.67f3c773.otf) format('truetype');
}
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
 background-color: black;

  
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel-container {
    width: 100% !important;
    height: 100% !important;
     
  }
