@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

@font-face {
  font-family: 'Rajdhani';
  src: local('Gallient'), url('./fonts/Gallient-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Rajdhani';
  src: local('Grotesk-Regular'), url('./fonts/AllrounderGroteskTest-Regular.otf') format('truetype');
}
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
 background-color: black;

  
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #888;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}